import { Empty, Table } from "antd";
import Panel from "components/modules/panel/panel";
import { t } from "i18next";
import { useEffect, useState } from "react";

export default function ApplicantsMotivationPanel(props) {

    const columns = [
        {
            title: "Category",
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: "Percentage",
            dataIndex: 'percentage',
            key: 'percentage',
        },
    ];

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (props.data && props.data.motivators && props.data.motivators.length > 0) {
            setItems(props.data.motivators.sort((a, b) => b.percentage - a.percentage).map((x, i) => {
                return {
                    key: i,
                    category: t(x.choice),
                    percentage: parseFloat(x.percentage).toFixed(2) + "%"
                }
            }));
        }
        if (props.data && props.data.motivators !== undefined && (props.data.motivators === null)) {
            setItems([]);
        }
    }, [props.data])

    return <Panel
        title={t("business_panel_motivation_title")}
        className="no-padding"
        scroll
        scrollHeight={200}
    >
        <Table locale={{
            emptyText: <>
                <p className="my-2 color-grey">{t("business_extra_no_data")}</p>
            </>
        }} className="m-0" pagination={false} columns={columns} dataSource={items} />
    </Panel>
}